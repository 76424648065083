import { AfterViewInit, Component, HostBinding } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { SystemService } from './core/system/system.service';
import { InitializationStateEnum } from './core/system/enum/initialization-state.enum';
import { UserService } from './module/user/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    @HostBinding('class') classes = 'ui-themed-container ui-application-container';
    protected actualNavBarState = 'ui-app-container';
    protected actualTheme = 'ui-default-theme';

    public body: HTMLElement = document.querySelector('body') as HTMLElement;
    title = 'zeebe-housekeeper';

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected systemService: SystemService,
        protected userService: UserService
    ) {
        this.systemService.initialize(route, router);
    }

    public ngAfterViewInit() {
        const token = this.systemService.getLocalStorage('token');
        if (token) {
            this.userService.getUserInformation(this.systemService.getLocalStorage('userId') as string).subscribe(
                (user) => {
                    this.systemService.setUser(user);
                }, (error) => {
                    console.error(error);
                    this.systemService.setUser(undefined);
                }
            );
        } else {
            this.router.navigate(['iam/sign/in']);
        }

        this.systemService.watchInitialized().subscribe((initialized) => {
            if (initialized === InitializationStateEnum.INITIALIZED) {
                this.removeFullLoading();
            }
        });
        this.systemService.watchTheme().subscribe((theme) => {
            this.body.classList.remove(this.actualTheme);
            this.body.classList.add(theme);
            this.actualTheme = theme;
        });
    }

    protected removeFullLoading(): void {
        const loadingElement = document.getElementById('loading-panel');
        if (loadingElement) {
            loadingElement.remove();
        }
    }
}
