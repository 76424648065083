<div class = "ui-error-container">
    <span class = "fa-solid ui-icon ui-icon-activated" [ngClass] = "{
        'fa-ghost': !isForbidden,
        'fa-person-military-pointing': isForbidden
    }" ></span>
    <div class = "ui-error-message-bold">
        {{ errorMessageBold }}
    </div>
    <div class = "ui-error-message">
        {{ errorMessage }}
    </div>
</div>
