import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'SizeFormat'})
export class SizeFormatPipe implements PipeTransform {
    public transform(value: number): string {
        const adjustedValue = (value) / 1024 / 1024;
        if (adjustedValue < 1) {
            return parseFloat((adjustedValue * 1024).toString()).toFixed(1) + 'KB';
        } else {
            return Math.floor((adjustedValue) / 1024 / 1024) + ' MB';
        }
    }
}
