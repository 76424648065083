import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'VolumeFormat'})
export class VolumeFormatPipe implements PipeTransform {
    public transform(value: number): string {

        if (value > 1000000) {
            return  Math.floor(value / 1000) + ' M';
        } else if (value > 1000) {
            return  Math.floor(value / 1000) + ' K';
        } else {
            return value.toString();
        }
    }
}
