import { Pipe, PipeTransform } from '@angular/core';
import { StatusEnum } from '../definition/enum/status.enum';

@Pipe({name: 'StatusFormat'})
export class StatusFormatPipe implements PipeTransform {
    public transform(status: StatusEnum): string {
        if (status === StatusEnum.AWAITING) {
            return 'Aguardando';
        } else if (status === StatusEnum.EXECUTING) {
            return 'Processando';
        } else {
            return 'Processado'
        }
    }
}
