import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { InitializationStateEnum } from './enum/initialization-state.enum';
import { themeParameter } from '../theme.parameter';
import { SignedInResultModel } from '../../iam/model/signed-in-result.model';
import { UserModel } from '../../module/user/model/user.model';

@Injectable({
    providedIn: 'root'
})
export class SystemService {
    initialized$: BehaviorSubject<InitializationStateEnum> = new BehaviorSubject<InitializationStateEnum>(InitializationStateEnum.AWAITING);
    navBarState$: BehaviorSubject<string> = new BehaviorSubject('');
    theme$: BehaviorSubject<string> = new BehaviorSubject('');
    user: UserModel | undefined = undefined;

    constructor(
        protected router: Router
    ) {}

    public initialize(route: ActivatedRoute, router: Router): void {

        this.theme$.next(this.getActualTheme());
        this.navBarState$.next(this.getNavBarState());

        if (!this.getLocalStorage('token')) {
            this.setInitialized();
        }
    }
    public getLocalStorage(key: string): string | null {
        return localStorage.getItem(key);
    }

    public getActualTheme(): string {
        const actualTheme = this.getLocalStorage('theme');
        return actualTheme ? actualTheme: 'default-theme';
    }

    public getUser(): UserModel {
        return this.user as UserModel;
    }
    public getNavBarState(): string {
        const navBarState = this.getLocalStorage('navBarState');
        return navBarState ? navBarState : 'side-retracted';
    }
    public haveUser(): boolean {
        return this.user !== undefined;
    }
    protected setInitialized(): void {
        this.initialized$.next(InitializationStateEnum.INITIALIZED);
    }
    public setLocalStorage(key: string, value: string): void {
        localStorage.setItem(key, value);
    }
    public setSignInInformation(signInResul: SignedInResultModel): void {
        this.user = signInResul.user;
        this.setLocalStorage('token', signInResul.session.token);
        this.setLocalStorage('sessionId', signInResul.session._id);
        this.setLocalStorage('userId', signInResul.session.userId);
    }
    public setUser(user: any): void {
        this.user = user;
        this.setInitialized();
    }
    public signOut(): void {
        this.user = undefined;
        this.removeLocalStorage('token');
        this.removeLocalStorage('sessionId');
        this.removeLocalStorage('userId');
        this.router.navigate(['/iam/sign/in']);
    }
    public removeLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }
    public watchInitialized(): Observable<InitializationStateEnum> {
        return this.initialized$;
    }
    public watchTheme(): Observable<string> {
        return this.theme$.pipe(
            filter((value: string) => value !== ''),
            map((theme: string) => {
                return themeParameter[theme];
            })
        );
    }
}
