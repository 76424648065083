import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserStatusEnum } from './enum/user-status.enum';
import { UserPasswordChangeModel } from './model/user-password-change.model';
import { UserModel } from './model/user.model';
import { environment } from '../../../environments/environment';
import { PaginatedModel } from '../../shared/component/pagination/model/paginated.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    BASE = environment.USER;
    constructor(
        protected httpService: HttpClient
    ) { }

    public getUser(userId: string): Observable<UserModel> {
        return this.httpService.get<UserModel>(`${this.BASE}/user/${userId}`);
    }
    public getUserInformation(userId: string): Observable<UserModel> {
        return this.httpService.get<UserModel>(`${this.BASE}/user/${userId}`);
    }
    public getUsers(filterValue?: string, page = 1, limit = 10): Observable<PaginatedModel<UserModel>> {
        let queryParams = '';
        if (filterValue) {
            queryParams = `?filterValue=${filterValue}`;
        }
        queryParams += `${queryParams ? '&' : '?'}limit=${limit}&page=${page}`;
        return this.httpService.get<PaginatedModel<UserModel>>(`${this.BASE}/user${queryParams}`);
    }
    public passwordChange(passwordChange: UserPasswordChangeModel): Observable<void> {
        return this.httpService.patch<void>(`${this.BASE}/user/password/change`, passwordChange);
    }
    public updateUser(userId: string, user: Partial<UserModel>): Observable<UserModel> {
        return this.httpService.patch<UserModel>(`${this.BASE}/user/${userId}`, user);
    }
}
