import { Pipe, PipeTransform } from '@angular/core';

import { UserStatusEnum } from '../../module/user/enum/user-status.enum';

@Pipe({name: 'UserStatusFormat'})
export class UserStatusFormat implements PipeTransform {
    public transform(status: UserStatusEnum): string {
        if (status === UserStatusEnum.ACTIVE) {
            return 'Ativo';
        } else if (status === UserStatusEnum.WITHOUT_ACCESS) {
            return 'Sem acesso';
        } else if (status === UserStatusEnum.PASSWORD_RESET) {
            return 'Reset'
        } else {
            return 'Bloqueado';
        }
    }
}
