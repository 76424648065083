import { Component, OnInit } from '@angular/core';
import { AbstractComponent } from '../../../shared/definition/abstract/abstract.component';
import { mainStates, managementStates } from './app-states.const';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { StateModel } from '../../../shared/definition/model/state.model';
import { SystemService } from '../../system/system.service';
import { IamService } from '../../../iam/iam.service';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent extends AbstractComponent implements OnInit {
    states: {
        all: Array<StateModel>;
        main: Array<StateModel>;
        management: Array<StateModel>;
    } = {
        all: [],
        main: [],
        management: []
    };

    actualState: StateModel | undefined;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected systemService: SystemService,
        protected signService: IamService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.initializeUserStates();
        this.watchRoutes();
    }

    public eventSignOut(): void {
        const sessionId = this.systemService.getLocalStorage('sessionId') as string;
        this.subscriptions$.add(this.signService.signOut(sessionId).subscribe(() => {
            this.systemService.signOut();
        }));
    }
    protected setState(stateName: string): boolean {
        this.actualState = this.states.all.find((state) => state.base === stateName);
        return this.actualState !== undefined;
    }
    protected getUserPhoto(): string {
        return this.systemService.getUser().detail.profilePhoto;
    }
    protected initializeUserStates(): void {
        const user = this.systemService.getUser();
        let hasGodMode = false;
        let rolesNames = new Set();
        user.roles.forEach((role) => {
            if (role.godMode) {
                hasGodMode = true;
                return;
            } else {
                rolesNames.add(role.name);
            }
        })

        this.states.main = (hasGodMode) ? mainStates : mainStates.filter((state: StateModel) => {
            return state.roles.find((role) => rolesNames.has(role));
        });
        this.states.management = (hasGodMode) ? managementStates : managementStates.filter((state: StateModel) => {
            return state.roles.find((role) => rolesNames.has(role));
        });
        this.states.all = [...this.states.main, ...this.states.management];
    }
    protected watchRoutes(): void {
        this.setState(this.getDataFromState());

        this.subscriptions$.add(
            this.router.events.pipe(filter((event) => {
                return event instanceof NavigationEnd;
            })).subscribe(() => {
                this.setState(this.getDataFromState());
            })
        );
    }

    private getDataFromState(): string {
        let currentRoute = this.route.root;

        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }

        return currentRoute.snapshot.data['state'] as string;
    }
}
