import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuardService } from './core/interceptor/auth.guard.service';
import { ErrorComponent } from './core/component/error/error.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'sample',
        pathMatch: 'full'
    }, {
        path: 'iam',
        loadChildren: () => import('./iam/iam.module').then(module => module.IamModule),
    }, {
        path: 'sample',
        loadChildren: () => import('./module/sample/sample.module').then(module => module.SampleModule),
        canActivate: [ authGuardService ],
        data: {
            state: 'sample'
        }
    }, {
        path: 'exam',
        loadChildren: () => import('./module/exam/exam.module').then(module => module.ExamModule),
        canActivate: [ authGuardService ],
        data: {
            state: 'exam'
        }
    }, {
        path: 'user',
        loadChildren: () => import('./module/user/user.module').then(module => module.UserModule),
        canActivate: [ authGuardService ],
        data: {
            state: 'user'
        }
    }, {
        path: 'workflow-instance',
        loadChildren: () => import('./module/workflow-instance/workflow-instance.module').then(module => module.WorkflowInstanceModule),
        canActivate: [ authGuardService ],
        data: {
            state: 'workflow-instance'
        }
    }, {
        path: 'error',
        component: ErrorComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
