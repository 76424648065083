import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';

import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { SizeFormatPipe } from '../helper/size-format.pipe';
import { SpinnerModule } from './spinner/spinner.module';
import { BadgeModule } from './badge/badge.module';
import { UserSelectComponent } from './user-select/user-select.component';
import { StatusCssClassPipe } from '../helper/status-css-class.pipe';
import { VolumeFormatPipe } from '../helper/volume-format.pipe';
import { StatusFormatPipe } from '../helper/status-format.pipe';
import { UserStatusFormat } from '../helper/enum-format.pipe';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
    declarations: [
        MessageDialogComponent,
        SizeFormatPipe,
        VolumeFormatPipe,
        StatusCssClassPipe,
        StatusFormatPipe,
        UserStatusFormat,
        UserSelectComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,
        SpinnerModule,
        ReactiveFormsModule,
        MatTableModule,
        BadgeModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatTabsModule,
        MatStepperModule,
        NgxMaskPipe,
        NgxMaskDirective,
    ], exports: [
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,
        SpinnerModule,
        MatStepperModule,
        ReactiveFormsModule,
        MessageDialogComponent,
        MatAutocompleteModule,
        MatTableModule,
        BadgeModule,
        MatSelectModule,
        MatTabsModule,
        UserSelectComponent,

        VolumeFormatPipe,
        StatusCssClassPipe,
        StatusFormatPipe,
        SizeFormatPipe,
        UserStatusFormat,
        NgxMaskPipe,
        NgxMaskDirective,
    ],
    providers: [provideNgxMask()]
})
export class SharedModule { }
