import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../../module/user/user.service';
import { Subscription } from 'rxjs';
import { PaginatedModel } from '../pagination/model/paginated.model';
import { UserModel } from '../../../module/user/model/user.model';
import { AbstractComponent } from '../../definition/abstract/abstract.component';

@Component({
    selector: 'app-user-select',
    templateUrl: './user-select.component.html',
    styleUrl: './user-select.component.scss'
})
export class UserSelectComponent extends AbstractComponent implements OnInit {
    @Input() description: string = '';
    @Output() userSelected = new EventEmitter<string | undefined>();

    userFilterValue: string = '';
    selectedUser: UserModel | undefined;
    users: Array<UserModel>;
    request: Subscription = new Subscription();
    searching = true;

    constructor(
        protected userService: UserService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.searchUsers();
    }

    public eventInputClick(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
    }
    public eventUserSelected(index: number, user: UserModel): void {
        if (this.selectedUser && user._id === this.selectedUser._id) {
            this.selectedUser = undefined;
        } else {
            this.selectedUser = this.users[index];
        }

        this.userSelected.emit(this.selectedUser?._id);
    }
    public eventUserFilterChange(value: Event): void {
        this.userFilterValue = (value.target as HTMLInputElement).value;
        this.searchUsers();
    }
    protected handleUsers(users: PaginatedModel<UserModel>): void {
        this.users = users.items;
    }
    protected searchUsers(): void {
        this.request.unsubscribe();
        this.searching = true;
        this.request = this.userService.getUsers(this.userFilterValue).subscribe((users) => {
            this.searching = false;
            this.handleUsers(users);
        });
    }
}
