import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from '../../../shared/definition/abstract/abstract.component';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent extends AbstractComponent implements OnInit {
    isForbidden = false;
    errorMessageBold = '';
    errorMessage = '';
    constructor(
        protected route: ActivatedRoute
    ) {
        super();
    }

    public ngOnInit(): void {
        this.isForbidden = (this.route.snapshot.fragment === '403');
        this.errorMessageBold =  (this.isForbidden) ? 'Você não possui permissão!' : 'Rota não encontrada.'
        this.errorMessage = (this.isForbidden) ? 'Pera ai, pera ai, esta porta está fechada ?' : 'Estou perdido Wilson!';
    }
}
