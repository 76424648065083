import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SignedInResultModel } from './model/signed-in-result.model';
import { SignInModel } from './model/sign-in.model';
import { RoleModel } from './model/role.model';
import { UserModel } from '../module/user/model/user.model';
import { UserStatusEnum } from '../module/user/enum/user-status.enum';
import { UserCreationIamModel } from './model/user-creation-iam.model';

@Injectable({
    providedIn: 'root'
})
export class IamService {
    BASE = environment.SIGN;
    constructor(
        protected httpService: HttpClient
    ) { }

    public associateUserRoles(userRoles: { userId: string; roles: Array<string> }): Observable<void> {
        return this.httpService.put<void>(`${this.BASE}/iam/user/roles`, userRoles);
    }
    public createUser(user: UserCreationIamModel): Observable<UserModel> {
        return this.httpService.post<UserModel>(`${this.BASE}/iam/user`, user);
    }
    public deleteUser(userId: string): Observable<void> {
        return this.httpService.delete<void>(`${this.BASE}/iam/user/${userId}`);
    }
    public getUser(userId: string): Observable<UserModel> {
        return this.httpService.get<UserModel>(`${this.BASE}/iam/user/${userId}`);
    }
    public getRoles(): Observable<Array<RoleModel>> {
        return this.httpService.get<Array<RoleModel>>(`${this.BASE}/iam/role`);
    }
    public signIn(signForm: SignInModel): Observable<SignedInResultModel> {
        return this.httpService.post<SignedInResultModel>(`${this.BASE}/sign/in`, signForm, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    public passwordReset(userId: string): Observable<void> {
        return this.httpService.patch<void>(`${this.BASE}/iam/user/${userId}/password/reset`, {});
    }
    public signOut(sessionId: string): Observable<void> {
        return this.httpService.delete<void>(`${this.BASE}/sign/out/${sessionId}`);
    }
    public updateUser(userId: string, user: Partial<UserModel>): Observable<UserModel> {
        return this.httpService.patch<UserModel>(`${this.BASE}/iam/user/${userId}`, user);
    }
    public updateUserStatus(userId: string, status: UserStatusEnum): Observable<void> {
        return this.httpService.patch<void>(`${this.BASE}/iam/user/${userId}/status`, { status });
    }
}
